import { FeatureFlagsDecorator } from "./FeatureFlagsDecorator";
import { isObjectMatch } from "../../utils/is-object-match";
import { getCurrentQueryParams } from "../../utils/query-params";

export class AttributesObserverFeatureFlagsDecorator extends FeatureFlagsDecorator {
  getExperimentGroup(experimentName) {
    this.maybeUpdateAttributes();
    return super.getExperimentGroup(experimentName);
  }

  getFeatureValue(featureName, defaultValue) {
    this.maybeUpdateAttributes();
    return super.getFeatureValue(featureName, defaultValue);
  }

  getFeatureRawResult(featureName) {
    this.maybeUpdateAttributes();
    return super.getFeatureRawResult(featureName);
  }

  isFeatureOn(featureName) {
    this.maybeUpdateAttributes();
    return super.isFeatureOn(featureName);
  }

  isFeatureOff(featureName) {
    this.maybeUpdateAttributes();
    return super.isFeatureOff(featureName);
  }

  maybeUpdateAttributes() {
    const newAttributes = getCurrentQueryParams();
    const currentAttributes = super.getAttributes();

    const shouldUpdate = !isObjectMatch(currentAttributes, newAttributes);

    if (shouldUpdate) {
      super.setAttributes(newAttributes);
    }
  }
}
