import { generateUuid } from "./uuid";

const getLandingIdFromStorage = () => {
  try {
    return window.sessionStorage.getItem("nebula_landing_id");
  } catch (err) {
    console.error(err);
    return "";
  }
};

const saveLandingIdToStorage = (landingId) => {
  try {
    window.sessionStorage.setItem("nebula_landing_id", landingId);
  } catch (err) {
    console.error(err);
  }
};

export const getLandingId = () => {
  const landingIdKey = "landing_id";
  const searchParams = new URLSearchParams(window.location.search);
  const landingIdFromParams = searchParams.get(landingIdKey);

  const landingIdFromStorage = getLandingIdFromStorage();

  if (!landingIdFromParams && !landingIdFromStorage) {
    const newLandingId = generateUuid();

    saveLandingIdToStorage(newLandingId);
    searchParams.set(landingIdKey, newLandingId);
    window.history.replaceState(null, null, `?${searchParams.toString()}`);
    return newLandingId;
  }

  if (landingIdFromParams && !landingIdFromStorage) {
    saveLandingIdToStorage(landingIdFromParams);
    return landingIdFromParams;
  }

  if (landingIdFromParams !== landingIdFromStorage) {
    searchParams.set(landingIdKey, landingIdFromStorage);
    window.history.replaceState(null, null, `?${searchParams.toString()}`);
    return landingIdFromStorage;
  }

  return landingIdFromStorage;
};
