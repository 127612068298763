export class FeatureFlagsDecorator {
  constructor(featureFlags) {
    this.featureFlags = featureFlags;
  }

  getFeatures() {
    return this.featureFlags.getFeatures();
  }

  setForcedFeatureValues(featureValues) {
    this.featureFlags.setForcedFeatureValues(featureValues);
  }

  getAttributes() {
    return this.featureFlags.getAttributes();
  }

  getExperimentGroup(experimentName) {
    return this.featureFlags.getExperimentGroup(experimentName);
  }

  getFeatureRawResult(featureName) {
    return this.featureFlags.getFeatureRawResult(featureName);
  }

  getFeatureValue(featureName, defaultValue) {
    return this.featureFlags.getFeatureValue(featureName, defaultValue);
  }

  isFeatureOff(featureName) {
    return this.featureFlags.isFeatureOff(featureName);
  }

  isFeatureOn(featureName) {
    return this.featureFlags.isFeatureOn(featureName);
  }

  setAttributes(attributes) {
    this.featureFlags.setAttributes(attributes);
  }

  setStateUpdateHandler(callback) {
    this.featureFlags.setStateUpdateHandler(callback);
  }

  setIdentifier(identifier) {
    this.featureFlags.setIdentifier(identifier);
  }

  onTrackExperiment(callback) {
    this.featureFlags.onTrackExperiment(callback);
  }
}
