import React, { createContext, useEffect, useState } from "react";

export const FeatureFlagsContext = createContext(null);

export const FeatureFlagsProvider = ({ children, featureFlags }) => {
  const [, setStateUpdateCount] = useState(0);

  useEffect(() => {
    const noop = () => {};

    if (!featureFlags) return noop();

    featureFlags.setStateUpdateHandler(() => {
      setStateUpdateCount((value) => value + 1);
    });

    return () => {
      featureFlags.setStateUpdateHandler(noop);
    };
  }, [featureFlags]);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
  );
};
