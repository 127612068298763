import React from "react";
import { useGetResultURL } from "../../hooks/useGetResultUrl";
import "./ImagedLanding.scss";
import useQueryParams from "../../hooks/useQueryParams";
import { ampBackendSentButtonClickEvent } from "../../analytics/amplitude";
import { useZodiacCompatibility } from "../../hooks/useZodiacCompatibility";

const ImagedLanding = ({ imageUrl }) => {
  const { query } = useQueryParams();

  useZodiacCompatibility();

  let oneLinkId = "c5157ae9";
  if (query.one_link_id) {
    oneLinkId = query.one_link_id;
  }
  const href = `https://nebulaapp.onelink.me/AaEA/${oneLinkId}`;
  const resultURL = useGetResultURL(href);

  let image = query.image || imageUrl || "zodiac-compatibility-landing_image.png";
  image = `https://media.nebulahoroscope.com/dynamic_landing/${image}`;

  const click = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    ampBackendSentButtonClickEvent("full-page");
  };

  return (
    <a href={resultURL} onClick={(e) => { click(e); }}>
      <div className="image-container">
        <img
          src={image}
          alt="section-content"
          className="image-app-image"
        />
      </div>
    </a>
  );
};

export default ImagedLanding;
