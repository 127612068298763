export const featureFlagsConfig = {
  features: {
    "/test": {
      defaultValue: { image: "zodiac-answers-relationships.png" },
      rules: [
        {
          condition: {
            utm_source: { $eq: "tiktok" },
          },

          key: "experimentName",
          variations: [
            {
              image: "zodiac-answers-relationships.png",
              groupName: "group1",
            },
            {
              path: "/zodiac-compatibility-landing-tiktok-2",
              groupName: "group2",
            },
            {
              path: "/zodiac-compatibility-rose",
              groupName: "group3",
            },
          ],
        },
      ],
      weights: [0.33, 0.33, 0.33],
    },
    "/zodiac-compatibility-rose": {
      defaultValue: {
        path: "/zodiac-compatibility-rose",
        image: "zodiac-astrology-statements.png",
      },
    },
    // "/zodiac-compatibility-rose": {
    //   defaultValue: {
    //     path: "/zodiac-compatibility-rose",
    //     groupName: null,
    //   },
    //   rules: [
    //     {
    //       condition: {
    //         utm_source: { $eq: "Social_facebook" },
    //       },
    //       key: "small_quiz",
    //       variations: [
    //         {
    //           groupName: "zodiac_astrology_statements",
    //           image: "zodiac-astrology-statements.png",
    //         },
    //         {
    //           groupName: "zodiac_compatibility_interface_blur",
    //           image: "zodiac_compatibility_interface_blur.jpg",
    //         },
    //       ],
    //       weights: [0.5, 0.5],
    //     },
    //   ],
    // },
  },
};
