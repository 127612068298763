const version = {
  fb: "fb",
};

const subdomainIndex = {
  com: "0",
  "facebook.com": "1",
  "www.facebook.com": "2",
};

const createFbp = () => [
  version.fb,
  subdomainIndex["facebook.com"],
  Date.now(),
  Math.round(Math.random() * 1e10),
].join(".");

const createFbc = () => {
  const { searchParams } = new URL(window.location.href);
  const fbClickId = searchParams.get("fbclid");
  return fbClickId && [
    version.fb,
    subdomainIndex["facebook.com"],
    Date.now(),
    fbClickId,
  ].join(".");
};

export const getPixelParameters = () => {
  const pixelParameters = document.cookie
    .split("; ")
    .filter((c) => c.startsWith("_fbp") || c.startsWith("_fbc"))
    .reduce((ids, c) => {
      if (c.startsWith("_fbp")) {
        return {
          ...ids,
          fbp: c.replace("_fbp=", ""),
        };
      }
      if (c.startsWith("_fbc")) {
        return {
          ...ids,
          fbc: c.replace("_fbc=", ""),
        };
      }
      return ids;
    }, {});

  if (!pixelParameters.fbp) {
    pixelParameters.fbp = createFbp();
  }

  if (!pixelParameters.fbc) {
    pixelParameters.fbc = createFbc();
  }

  return pixelParameters;
};

export const logEvent = (eventName, params = {}) => {
  const { fbq } = window;
  fbq("track", eventName, params);
};

export const fbqPurchase = () => {
  const { fbq } = window;
  fbq("track", "Purchase", {
    currency: "USD",
    value: 19.99,
  });
};

export const fbqPurchaseDiscount = () => {
  const { fbq } = window;
  fbq("track", "Purchase", {
    currency: "USD",
    value: 9.99,
  });
};

export const fbqSubscription = (price) => {
  const { fbq } = window;
  fbq("track", "Purchase", {
    currency: "USD",
    value: price,
  });
};

export const fbqTestPurchase = () => {
  const { fbq } = window;
  fbq("track", "Purchase", {
    currency: "USD",
    value: 30.00,
  });
};
