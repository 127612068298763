export function getLandingName() {
  const urlParams = new URLSearchParams(window.location.search);
  let name = urlParams.get("landing_name");

  if (!name) {
    name = window.location.pathname?.split("/")[1] || "unknown";
  }

  return name;
}
