export const processMarketingStrategyOnURL = (url, strategy, query) => {
  switch (strategy) {
    case "google": {
      if (query.gclid !== null) {
        url.searchParams.append("gclid", query.gclid);
        url.searchParams.append("deep_link_sub2", query.gclid);
      }
      break;
    }
    default: {
      if (url.searchParams.has("landing_id") === false) {
        url.searchParams.append("landing_id", query.landing_id);
      }
      url.searchParams.append("deep_link_sub1", query.landing_id);
      break;
    }
  }

  return url;
};
