import useQueryParams from "../hooks/useQueryParams";

export function getGoogleAdsClickID() {
  const url = new URL(window.location.href);
  return url.searchParams.get("gclid");
}

export const findOrGetGoogleClickId = async () => {
  const { query } = useQueryParams();
  const { gclid } = query;

  return gclid ?? null;
};

export const googleClickIdOnSubmit = (event) => {
  event.preventDefault();
  const action = event.target.getAttribute("action");
  findOrGetGoogleClickId().then((gclid) => {
    if (gclid !== null) {
      const url = new URL(action);
      const currentURL = new URL(window.location.href);
      const skipParams = ["gclid"];
      currentURL.searchParams.forEach((value, key) => {
        if (!skipParams.includes(key)) {
          url.searchParams.append(key, value);
        }
      });
      url.searchParams.append("gclid", gclid);
      url.searchParams.append("deep_link_sub2", gclid);
      window.open(url.href, "_self");
    }
  }).catch(() => {
    const url = new URL(action);
    url.searchParams.append("gclid", "error");
    url.searchParams.append("deep_link_sub2", "error");
    window.open(url.href, "_self");
  });
};

export const gaProductDisplayEvent = () => {
  const { ga } = window;
  ga("create", "UA-182618786-1");
  ga("require", "ec");

  ga("ec:addImpression", {
    id: "1",
    name: "Compatibility report",
    position: 1,
  });

  ga("send", "pageview");
};
