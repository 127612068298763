import {
  LandingIdFeatureFlagsDecorator,
  GrowthBookFeatureFlags,
  QuerySettingFeatureFlagsDecorator,
} from "../services";
import { featureFlagsConfig } from "../config";
import { trackExperiment } from "../../analytics/events";
import { AttributesObserverFeatureFlagsDecorator } from "../services/AttributesObserverFeatureFlagsDecorator";

export const initFeatureFlags = () => {
  const growthBookFeatureFlags = new GrowthBookFeatureFlags(featureFlagsConfig);
  const withAttributesObserver = new AttributesObserverFeatureFlagsDecorator(growthBookFeatureFlags);
  const withIdentify = new LandingIdFeatureFlagsDecorator(withAttributesObserver);
  const withQuerySetting = new QuerySettingFeatureFlagsDecorator(
    withIdentify,
  );

  withQuerySetting.onTrackExperiment((experiment = {}, result = {}) => {
    const { key } = experiment;
    const { value } = result;

    if (Object.prototype.hasOwnProperty.call(value, "groupName")) {
      trackExperiment(key, value.groupName);
      return;
    }

    trackExperiment(key, value);
  });

  return withQuerySetting;
};
