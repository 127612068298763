import { FeatureFlagsDecorator } from "./FeatureFlagsDecorator";
import { getCurrentQueryParams } from "../../utils/query-params";

export class QuerySettingFeatureFlagsDecorator extends FeatureFlagsDecorator {
  constructor(featureFlags) {
    super(featureFlags);
    this.loadFromQueries();
  }

  loadFromQueries() {
    const queries = getCurrentQueryParams();

    if (!Object.keys(queries).length) return;

    super.setForcedFeatureValues(queries);
  }
}
