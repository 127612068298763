export const PRIVACY_POLICY = "/NebulaCompatibility_PrivacyPolicy.pdf";

export const REFUND_POLICY = "https://instant-game-say-what.s3.us-east-2.amazonaws.com/PROKETO/REFUND_POLICY_-_web_project.pdf";

export const TERMS_OF_SERVICE = "/NebulaCompatibilty_ToS.pdf";

export const APP_STORE = "https://apps.apple.com/us/app/nebula-horoscope-astrology/id1459969523";

export const GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=genesis.nebula&hl=en";

export const NATAL_CHART_REPORT = "http://natal-chart.nebulahoroscope.com/";

export const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NEXT_PAGES = [
  "/gender",
  "/how",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/firstLoading",
  "/influence",
  "/trust",
  "/overthink",
  "/remember",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/secondLoading",
  "/wow",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/thirdLoading",
  "/email",
  "/ready",
  "/billing",
  "/additionalDiscount",
];

export const APP_THIRD_NEXT_PAGE = [
  "/gender",
  "/how",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/firstLoading",
  "/influence",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/secondLoading",
  "/wow",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
  "/thirdLoading",
  "/email",
  "/ready",
  "/billing",
  "/additionalDiscount",
];

export const APP_SECOND_NEXT_PAGE = [
  "/gender",
  "/how",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/firstLoading",
  "/influence",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/mad",
  "/write",
  "/friendsDescribe",
  "/colorPick",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/secondLoading",
  "/wow",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
  "/thirdLoading",
  "/email",
  "/ready",
  "/billing",
  "/additionalDiscount",
];

export const APP_FOURTH_NEXT_PAGE = [
  "/gender",
  "/how",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/firstLoading",
  "/influence",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/mad",
  "/write",
  "/friendsDescribe",
  "/colorPick",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/secondLoading",
  "/wow",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
  "/thirdLoading",
  "/email",
  "/billing",
  "/additionalDiscount",
];

export const APP_SECOND_ZODIAC_NEXT_PAGE = [
  "/gender",
  "/how",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/firstLoading",
  "/influence",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/mad",
  "/write",
  "/friendsDescribe",
  "/colorPick",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/secondLoading",
  "/wow",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
  "/thirdLoading",
  "/ready",
];

export const PREV_PAGES = [
  "/gender",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/trust",
  "/overthink",
  "/remember",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
];

export const APP_SECOND_PREV_PAGE = [
  "/gender",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/mad",
  "/write",
  "/friendsDescribe",
  "/colorPick",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
];

export const APP_FOURTH_PREV_PAGE = [
  "/gender",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/mad",
  "/write",
  "/friendsDescribe",
  "/colorPick",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
];

export const APP_SECOND_ZODIAC_PREV_PAGE = [
  "/gender",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/mad",
  "/write",
  "/friendsDescribe",
  "/colorPick",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
];

export const APP_THIRD_PREV_PAGE = [
  "/gender",
  "/how",
  "/dateOfBirth",
  "/placeOfBirth",
  "/timeOfBirth",
  "/influence",
  "/trust",
  "/overthink",
  "/remember",
  "/naturalElements",
  "/datesTypes",
  "/success",
  "/didYouKnow",
  "/partnersGender",
  "/partnerDate",
  "/partnerPlace",
  "/partnerTime",
  "/wow",
  "/meFirst",
  "/straightforward",
  "/finalDecisions",
  "/detailOriented",
  "/describePartner",
  "/introvert",
  "/email",
  "/ready",
  "/billing",
];

export const PERSONAL_PLANS = [
  {
    name: "Advanced Compatibility report",
    price: "39.99$",
    description:
      "Advanced analysis of compatibility and relationships. This reading compares the charts of two individuals and analyzes their psychological, emotional, intellectual and sexual compatibility.",
    result: "It will help you to discover your beloved ones in a new way.",
  },
];

export const MONTH_LIST = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DAY_LIST = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const HOUR_LIST = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const MINUTE_LIST = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export const DAY_PARTS = ["AM", "PM"];

export const YEAR_LIST = (() => {
  const limitYear = 2003;
  const years = [];
  for (let i = 1940; i <= limitYear; i++) {
    years.push(i);
  }
  return years;
})();

export const PAYMENT_LOGOS_ARRAY = [
  "ssl",
  "mastercard",
  "maestro",
  "jcb",
  "americanexpress",
  "union",
  "norton",
  "visa",
  "mcafee",
  "discover",
  "paypal",
];

export const FAQ = [
  {
    title: "What happens after I order?",
    answer:
      "After you place your order we get to work! Based on the questions you answered in the quiz, our astrologers will craft your report in based on your natal chart, stars and planets positioning. Then it is double checked by another astrologer to be sure that we provide you with the most accurate reading",
  },
  {
    title: "When do I get my report?",
    answer:
      "Normally, it takes up to 2 days for your plan to be delivered to your email. Why so long?  It takes up to 5 hours to prepare your natal chart and another 3-4 hours to read it. We normally send the report faster, but since they are highly accurate and personalized, we require some time to make a good quality reading from you",
  },
  {
    title: "Is this a one-time payment?",
    answer:
      "Yes, it is a one-time payment. No subscriptions, additional charges or hidden fees will ever occur.",
  },
];

export const listStepData = [
  "gender",
  "birthPlace.place",
  "partnerGender",
  "partnerBirthPlace.partnerPlace",
];
