import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import useQueryParams from "./useQueryParams";
import { getLandingName } from "../utils/landing-name";
import { trackLandingOpen } from "../analytics/events";

export const useZodiacCompatibility = () => {
  const history = useHistory();
  const [landingId, setLandingId] = useState("");
  const [oneLinkId, setOneLinkId] = useState("");
  const { query } = useQueryParams();

  useEffect(() => {
    if (query.onelinkid) {
      setOneLinkId(query.onelinkid);
    }

    if (query.landing_id) {
      setLandingId(query.landing_id);
    }
  }, []);

  useEffect(() => {
    trackLandingOpen(query).catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (landingId) {
      const currentURL = new URL(window.location.href);
      if (currentURL.searchParams.has("landing_id") === false) {
        currentURL.searchParams.append("landing_id", landingId);
      }

      if (currentURL.searchParams.has("one_link_id") === false && oneLinkId) {
        currentURL.searchParams.append("one_link_id", oneLinkId);
        currentURL.searchParams.delete("onelinkid");
      }
      if (currentURL.searchParams.has("landing") === false) {
        currentURL.searchParams.append("landing", getLandingName());
      }
      if (currentURL.searchParams.has("deep_link_sub3") === false) {
        currentURL.searchParams.append("deep_link_sub3", getLandingName());
      }

      history.replace({
        search: currentURL.search,
      });
    }
  }, [landingId, oneLinkId]);
};
