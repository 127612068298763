import React, { useState } from "react";
import { Feature } from "../../feature-flags/ui";
import ImagedLanding from "../ImagedLanding";
import { SITES_MAP } from "../../sitesMap";
import { getPathnameFirstPart } from "../../utils/url";

const ExperimentalLanding = ({ fallback: FallbackLanding }) => {
  const [pathname] = useState(() => getPathnameFirstPart());

  return (
    <Feature name={pathname}>
      {(featureValue) => {
        if (!featureValue || !featureValue.value) return <FallbackLanding />;

        if (featureValue.value.image) {
          return <ImagedLanding imageUrl={featureValue.value.image} />;
        }

        if (featureValue.value.path && SITES_MAP[featureValue.value.path]) {
          const Site = SITES_MAP[featureValue.value.path];

          return <Site />;
        }

        return <FallbackLanding />;
      }}
    </Feature>
  );
};

export default ExperimentalLanding;
