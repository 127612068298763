import { GrowthBook } from "@growthbook/growthbook-react";

export class GrowthBookFeatureFlags {
  constructor(passedConfig = {}) {
    this.trackExperimentListeners = [];

    const notifyTrackExperimentCallback = this.notifyTrackExperimentListeners.bind(this);

    this.growthBookInstance = new GrowthBook({
      ...passedConfig,
      trackingCallback: notifyTrackExperimentCallback,
    });
  }

  getFeatures() {
    return this.growthBookInstance.getFeatures();
  }

  setForcedFeatureValues(featureValues) {
    const map = new Map(Object.entries(featureValues));
    this.growthBookInstance.setForcedFeatures(map);
  }

  getAttributes() {
    return this.growthBookInstance.getAttributes();
  }

  setAttributes(attributes) {
    const currentAttributes = this.getAttributes();
    const newAttributes = {
      ...currentAttributes,
      ...attributes,
    };
    this.growthBookInstance.setAttributes(newAttributes);
    console.info("FeatureFlags attribute updated:", {
      data: newAttributes,
    });
  }

  setIdentifier(identifier) {
    this.setAttributes({ id: identifier });
  }

  setStateUpdateHandler(callback) {
    this.growthBookInstance.setRenderer(callback);
  }

  isFeatureOn(featureName) {
    return this.growthBookInstance.isOn(featureName);
  }

  isFeatureOff(featureName) {
    return this.growthBookInstance.isOff(featureName);
  }

  getFeatureValue(featureName, defaultValue) {
    return this.growthBookInstance.getFeatureValue(featureName, defaultValue);
  }

  getFeatureRawResult(featureName) {
    return this.growthBookInstance.evalFeature(featureName);
  }

  getExperimentGroup(experimentName) {
    const result = this.getFeatureRawResult(experimentName);

    if (!result || !result.value) {
      console.warn("Experiment not found", {
        data: experimentName,
      });
      return null;
    }

    return result.value;
  }

  onTrackExperiment(callback) {
    this.trackExperimentListeners.push(callback);
  }

  notifyTrackExperimentListeners(
    experiment,
    result,
  ) {
    this.trackExperimentListeners.forEach((listener) => {
      try {
        listener(experiment, result);
      } catch (err) {
        console.error(err);
      }
    });
  }
}
