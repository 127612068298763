import { useEffect, useState } from "react";
import { getCurrentQueryParams } from "../utils/query-params";
import { useLocation } from "react-router-dom";

export default function useQueryParams() {
  const location = useLocation();
  const [query, setQuery] = useState(() => getCurrentQueryParams());

  useEffect(() => {
    setQuery(getCurrentQueryParams());
  }, [location]);

  return { query };
}
