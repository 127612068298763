import {
  APP_SECOND_NEXT_PAGE,
  APP_SECOND_PREV_PAGE,
  APP_SECOND_ZODIAC_NEXT_PAGE,
  APP_SECOND_ZODIAC_PREV_PAGE,
  APP_THIRD_NEXT_PAGE,
  APP_THIRD_PREV_PAGE,
  listStepData,
  MONTH_LIST,
  NEXT_PAGES,
  PREV_PAGES,
  APP_FOURTH_NEXT_PAGE,
  APP_FOURTH_PREV_PAGE,
} from "../etc/consts";

export const openInNewTab = (url) => () => window.open(url, "_blank");
export const setToStorage = (data, name = "NebulaState") => sessionStorage.setItem(name, JSON.stringify(data));
export const removeFromStorage = (name) => sessionStorage.setItem(name, null);
export const getFromStorage = (name = "NebulaState") => sessionStorage.getItem(name);

export const validateRequestData = (state) => {
  const newState = JSON.parse(JSON.stringify(state));
  newState.gender = newState.gender.toLowerCase();
  newState.partnerGender = newState.partnerGender.toLowerCase();
  newState.date.month = MONTH_LIST.findIndex((item) => item === newState.date.month) + 1;
  newState.partnerDate.month = MONTH_LIST.findIndex((item) => item === newState.partnerDate.month) + 1;
  newState.date.month = newState.date.month < 10 ? `0${newState.date.month}` : newState.date.month;
  newState.partnerDate.month = newState.partnerDate.month < 10
    ? `0${newState.partnerDate.month}`
    : newState.partnerDate.month;
  newState.date.day = newState.date.day.length < 2 ? `0${newState.date.day}` : newState.date.day;
  newState.partnerDate.day = newState.partnerDate.day.length < 2
    ? `0${newState.partnerDate.day}`
    : newState.partnerDate.day;
  newState.time.hour = getHour(
    newState.time.hour,
    newState.time.minutes,
    newState.time.dayPart,
  );
  newState.partnerTime.hour = getHour(
    newState.partnerTime.hour,
    newState.partnerTime.minutes,
    newState.partnerTime.dayPart,
  );
  return newState;
};

const getHour = (hour, minutes, dayPart) => {
  hour = dayPart === "PM" ? parseInt(hour) + 12 : hour;
  hour = hour === 24 ? "00" : hour;

  return hour;
};

export const delay = (ms = 300) => new Promise((resolve) => setTimeout(resolve, ms));

export const nextPageAppThird = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const nextPageInd = APP_THIRD_NEXT_PAGE.indexOf(currentPage);
  if (nextPageInd < 0) {
    const goBack = page.replace(currentPage, APP_THIRD_NEXT_PAGE[0]);
    return goBack;
  }
  const nextPage = page.replace(
    currentPage,
    APP_THIRD_NEXT_PAGE[nextPageInd + 1],
  );
  return nextPage;
};

export const nextPageAppSecond = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const nextPageInd = APP_SECOND_NEXT_PAGE.indexOf(currentPage);
  if (nextPageInd < 0) {
    const goBack = page.replace(currentPage, APP_SECOND_NEXT_PAGE[0]);
    return goBack;
  }
  const nextPage = page.replace(
    currentPage,
    APP_SECOND_NEXT_PAGE[nextPageInd + 1],
  );
  return nextPage;
};

export const nextPageAppFourth = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const nextPageInd = APP_FOURTH_NEXT_PAGE.indexOf(currentPage);
  if (nextPageInd < 0) {
    const goBack = page.replace(currentPage, APP_FOURTH_NEXT_PAGE[0]);
    return goBack;
  }
  const nextPage = page.replace(
    currentPage,
    APP_FOURTH_NEXT_PAGE[nextPageInd + 1],
  );
  return nextPage;
};

export const nextPageAppZodiacSecond = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const nextPageInd = APP_SECOND_ZODIAC_NEXT_PAGE.indexOf(currentPage);
  if (nextPageInd < 0) {
    const goBack = page.replace(currentPage, APP_SECOND_ZODIAC_NEXT_PAGE[0]);
    return goBack;
  }
  const nextPage = page.replace(
    currentPage,
    APP_SECOND_ZODIAC_NEXT_PAGE[nextPageInd + 1],
  );
  return nextPage;
};

export const nextPage = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const nextPageInd = NEXT_PAGES.indexOf(currentPage);
  if (nextPageInd < 0) {
    const goBack = page.replace(currentPage, NEXT_PAGES[0]);
    return goBack;
  }
  const nextPage = page.replace(currentPage, NEXT_PAGES[nextPageInd + 1]);
  return nextPage;
};

export const prevPageAppSecond = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const prevPageInd = APP_SECOND_PREV_PAGE.indexOf(currentPage);
  if (prevPageInd < 0) {
    const goBack = page.replace(currentPage, APP_SECOND_PREV_PAGE[0]);
    return goBack;
  }
  const prevPage = page.replace(
    currentPage,
    APP_SECOND_PREV_PAGE[prevPageInd - 1],
  );
  return prevPage;
};

export const prevPageAppFourth = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const prevPageInd = APP_FOURTH_PREV_PAGE.indexOf(currentPage);
  if (prevPageInd < 0) {
    const goBack = page.replace(currentPage, APP_FOURTH_PREV_PAGE[0]);
    return goBack;
  }
  const prevPage = page.replace(
    currentPage,
    APP_FOURTH_PREV_PAGE[prevPageInd - 1],
  );
  return prevPage;
};

export const prevPageAppZodiacSecond = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const prevPageInd = APP_SECOND_ZODIAC_PREV_PAGE.indexOf(currentPage);
  if (prevPageInd < 0) {
    const goBack = page.replace(currentPage, APP_SECOND_ZODIAC_PREV_PAGE[0]);
    return goBack;
  }
  const prevPage = page.replace(
    currentPage,
    APP_SECOND_ZODIAC_PREV_PAGE[prevPageInd - 1],
  );
  return prevPage;
};

export const prevPageAppThird = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const prevPageInd = APP_THIRD_PREV_PAGE.indexOf(currentPage);
  if (prevPageInd < 0) {
    const goBack = page.replace(currentPage, APP_THIRD_PREV_PAGE[0]);
    return goBack;
  }
  const prevPage = page.replace(
    currentPage,
    APP_THIRD_PREV_PAGE[prevPageInd - 1],
  );
  return prevPage;
};

export const currentStep = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const currentStep = APP_THIRD_PREV_PAGE.indexOf(currentPage);
  return currentStep;
};

export const prevPage = (page) => {
  const currentPageInd = page.lastIndexOf("/");
  const currentPage = page.slice(currentPageInd);
  const prevPageInd = PREV_PAGES.indexOf(currentPage);
  if (prevPageInd < 0) {
    const goBack = page.replace(currentPage, PREV_PAGES[0]);
    return goBack;
  }
  const prevPage = page.replace(currentPage, PREV_PAGES[prevPageInd - 1]);
  return prevPage;
};

export const definitionSign = (month, day) => {
  if ((month === "Mar" && day >= 21) || (month === "Apr" && day <= 20)) {
    return "Aries";
  }
  if ((month === "Apr" && day >= 21) || (month === "May" && day <= 20)) {
    return "Taurus";
  }
  if ((month === "May" && day >= 21) || (month === "Jun" && day <= 20)) {
    return "Gemini";
  }
  if ((month === "Jun" && day >= 21) || (month === "Jul" && day <= 22)) {
    return "Cancer";
  }
  if ((month === "Jul" && day >= 23) || (month === "Aug" && day <= 22)) {
    return "Leo";
  }
  if ((month === "Aug" && day >= 23) || (month === "Sep" && day <= 22)) {
    return "Virgo";
  }
  if ((month === "Sep" && day >= 23) || (month === "Oct" && day <= 22)) {
    return "Libra";
  }
  if ((month === "Oct" && day >= 23) || (month === "Nov" && day <= 22)) {
    return "Scorpio";
  }
  if ((month === "Nov" && day >= 23) || (month === "Dec" && day <= 21)) {
    return "Sagittarius";
  }
  if ((month === "Dec" && day >= 22) || (month === "Jan" && day <= 19)) {
    return "Capricorn";
  }
  if ((month === "Jan" && day >= 20) || (month === "Feb" && day <= 19)) {
    return "Aquarius";
  }
  if ((month === "Feb" && day >= 20) || (month === "Mar" && day <= 20)) {
    return "Pisces";
  }
  return null;
};

export const getNumberMonthFromString = (mon) => new Date(Date.parse(`${mon} 1, 2012`)).getMonth();

export const getDayList = (month, year) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    const day = String(new Date(date).getDate());

    days.push(day.length === 1 ? `0${day}` : day);
    date.setDate(date.getDate() + 1);
  }
  return days;
};

const getAssociateObjectValue = (obj, path) => {
  path = path.split(".");
  let current = obj;
  while (path.length) {
    if (typeof current !== "object") return undefined;
    current = current[path.shift()];
  }
  return current;
};

export const checkValidateStep = (lastStepDataName, stateData) => {
  let isValid = true;
  for (let i = 0; i < listStepData.length; i++) {
    if (getAssociateObjectValue(stateData, listStepData[i]).length < 1) {
      isValid = false;
      return false;
    }
    if (listStepData[i] === lastStepDataName) {
      break;
    }
  }
  return isValid;
};
