import React from "react";
import { useFeatureRawResult } from "../../hooks";

export const Feature = ({ children, name }) => {
  const rawResult = useFeatureRawResult(name);

  return <>{children(rawResult)}</>;
};

export default Feature;
