import amplitude from "amplitude-js";
import { backendLandingClick } from "../utils/requests";
import { getCurrentQueryParams } from "../utils/query-params";
import { getLandingId } from "../utils/landing-id";

const API_KEY = "14f40285c51a0825dd3a8fad6af8d1f6";
const BACKEND_BOARD_API_KEY = "b6f19d5b70de71528e194d7776f7001a";
const BACKEND_BOARD_INSTANCE_NAME = "backend_board";

export const initAmplitude = () => {
  amplitude.getInstance().init(API_KEY);
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .init(BACKEND_BOARD_API_KEY);
};

export const ampSetUserProps = (props) => {
  amplitude.getInstance().setUserProperties(props);
};

export const ampBackendSetUserProps = (props) => {
  amplitude.getInstance(BACKEND_BOARD_INSTANCE_NAME).setUserProperties(props);
};

export const ampClearUserProps = () => {
  amplitude.getInstance().clearUserProperties();
};

export const ampStartQuizEvent = (eventSource) => {
  amplitude.getInstance().logEvent("start_quiz", { source: eventSource });
};

export const ampViewProduct = (eventSource) => {
  amplitude.getInstance().logEvent("view_product", { source: eventSource });
};

export const ampAddToCart = (eventSource) => {
  amplitude.getInstance().logEvent("add_to_cart", { source: eventSource });
};

export const ampInitiateCheckout = (eventSource) => {
  amplitude
    .getInstance()
    .logEvent("initiate_checkout", { source: eventSource });
};

export const ampPayPaypal = (eventSource) => {
  amplitude.getInstance().logEvent("pay_paypal", { source: eventSource });
};

export const ampPayCard = (eventSource) => {
  amplitude.getInstance().logEvent("pay_card", { source: eventSource });
};

export const ampPurchaseSuccess = (eventSource) => {
  amplitude.getInstance().logEvent("purchase_success", { source: eventSource });
};

export const ampPurchaseSuccessDiscount = (eventSource) => {
  amplitude
    .getInstance()
    .logEvent("purchase_success_discount", { source: eventSource });
};

export const ampPurchaseError = (eventSource) => {
  amplitude.getInstance().logEvent("purchase_error", { source: eventSource });
};

export const ampFirstLoading = (eventSource) => {
  amplitude.getInstance().logEvent("first_loading", { source: eventSource });
};

export const ampSecondPartner = (eventSource) => {
  amplitude.getInstance().logEvent("second_partner", { source: eventSource });
};

export const ampSecondLoading = (eventSource) => {
  amplitude.getInstance().logEvent("second_loading", { source: eventSource });
};

export const ampEmailForm = (eventSource) => {
  amplitude.getInstance().logEvent("email_form", { source: eventSource });
};

export const ampDiscountPage = (eventSource) => {
  amplitude.getInstance().logEvent("discount_page", { source: eventSource });
};

export const ampEmailMarketingLpOneOpen = (eventSource) => {
  amplitude
    .getInstance()
    .logEvent("email_marketing_lp1_open", { source: eventSource });
};

export const ampEmailMarketingLpTwoOpen = (eventSource) => {
  amplitude
    .getInstance()
    .logEvent("email_marketing_lp2_open", { source: eventSource });
};

export const ampEmailMarketingLpThreeOpen = (eventSource) => {
  amplitude
    .getInstance()
    .logEvent("email_marketing_lp3_open", { source: eventSource });
};

export const ampBackendSentEvent = (eventName, eventRawData) => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .setUserId(eventRawData.landing_id);

  if (eventName === "landing_open") {
    amplitude.getInstance(BACKEND_BOARD_INSTANCE_NAME).setUserProperties({
      media_source: eventRawData.utm_source ?? null,
      campaign: eventRawData.utm_campaign ?? null,
      landing_name: eventRawData.landing ?? null,
    });
  }

  const eventData = {
    landing_id: getLandingId(),
    media_source: eventRawData.utm_source ?? null,
    utm: {},
    data: {},
  };
  if (eventRawData.landing) {
    eventData.landing_name = eventRawData.landing;
  }
  for (const eventProp in eventRawData) {
    if (eventProp.includes("utm_")) {
      eventData.utm[eventProp] = eventRawData[eventProp];
    } else {
      eventData.data[eventProp] = eventRawData[eventProp];
    }
  }

  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent(eventName, eventData);
};

export const ampBackendSentButtonClickEvent = (buttonText) => {
  const eventName = "download";
  const eventData = getCurrentQueryParams();
  eventData.button_text = buttonText;
  ampBackendSentEvent(eventName, eventData);
  backendLandingClick(eventData, eventData.landing_id);
};

export const smallQuizGenderOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_gender_open");
};

export const smallQuizGenderClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_gender_click");
};

export const smallQuizMentionedInOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_mentioned_screen_open");
};

export const smallQuizMentionedInClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_mentioned_screen_click");
};

export const smallQuizPersonalisationOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_personalisation_screen_open");
};

export const smallQuizPersonalisationClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_personalisation_screen_click");
};

export const smallQuizZodiacSignOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_your_zodiac_sign_open");
};

export const smallQuizZodiacSignClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_your_zodiac_sign_click");
};

export const smallQuizRelationshipOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_relationship_screen_open");
};

export const smallQuizRelationshipClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_relationship_screen_click");
};

export const smallQuizCongratulationOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_goal_congratulation_screen_open");
};

export const smallQuizCongratulationClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_goal_congratulation_screen_click");
};

export const smallQuizTypeReadingOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_type_reading_screen_open");
};

export const smallQuizTypeReadingClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_type_reading_screen_click");
};

export const smallQuizChartReadingOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_chart_screen_open");
};

export const smallQuizChartReadingClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_chart_reading_screen_click");
};

export const smallQuizGoalOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_goal_screen_open");
};

export const smallQuizGoalClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_goal_screen_click");
};

export const smallQuizPartnerZodiacSignOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_your_partner_zodiac_sign_open");
};

export const smallQuizPartnerZodiacSignClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_your_partner_zodiac_sign_click");
};

export const smallQuizLoaderOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_loader_open");
};

export const smallQuizReportOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_compatibility_open");
};

export const smallQuizReportClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_small_quiz_compatibility_click");
};

export const statementsScreenOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_screen_open");
};

export const statementsScreenClicked = (context) => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_screen_click", { context });
};

export const statementsRightPlaceScreenOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_right_place_screen_open");
};

export const statementsRightPlaceScreenClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_right_place_screen_click");
};

export const statementsReviewsScreenOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_reviews_screen_open");
};

export const statementsReviewsScreenClicked = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_reviews_screen_click");
};

export const statementsReasonGoalScreenOpened = () => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_reason_for_goal_screen_open");
};

export const statementsReasonGoalScreenClicked = (context) => {
  amplitude
    .getInstance(BACKEND_BOARD_INSTANCE_NAME)
    .logEvent("capi_statements_reason_for_goal_screen_click", { context });
};
