import { lazy } from "react";

const Nebulatalk = lazy(() => import("./sites/Nebulatalk/Nebulatalk"));
const ZodiacCompatibility = lazy(() => import("./sites/ZodiacLandings/ZodiacCompatibility/ZodiacCompatibility"));
const ZodiacCompatibilityAnswers = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityAnswers/ZodiacCompatibilityAnswers"
));
const ZodiacCompatibilityNew = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityNew/ZodiacCompatibilityNew"
));
const ZodiacCompatibilityNewV2 = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityNewV2/ZodiacCompatibilityNew"
));
const ZodiacCompatibilityRose = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityRose/ZodiacCompatibilityRose"
));
const AstrologyCompatibilityConcept = lazy(() => import(
  "./sites/ZodiacLandings/AstrologyCompatibilityConcept/AstrologyCompatibilityConcept"
));
const ZodiacCompatibilityValentine = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityValentine/ZodiacCompatibilityValentine"
));
const ZodiacCompatibilityRedV2 = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityRedV2/ZodiacCompatibilityRedV2"
));
const ZodiacCompatibilityRed = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityRed/ZodiacCompatibilityRed"
));
const ZodiacCompatibilityPurple = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityNewPurple/ZodiacCompatibilityNewPurple"
));
const ZodiacCompatibilityPlaymarket = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityPlaymarket/ZodiacCompatibilityPlaymarket"
));
const ZodiacCompatibilityXmas = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityXmas/ZodiacCompatibilityXmas"
));
const ZodiacQuestions = lazy(() => import("./sites/ZodiacLandings/ZodiacQuestions/ZodiacQuestions"));
const ZodiacAppstore = lazy(() => import("./sites/ZodiacLandings/ZodiacAppstore/ZodiacAppstore"));
const ZodiacAstrologyStore = lazy(() => import("./sites/ZodiacLandings/ZodiacAstrologyStore/ZodiacAstrologyStore"));
const ZodiacChatsReview = lazy(() => import("./sites/ZodiacLandings/ZodiacChatsReview/ZodiacChatsReview"));
const ZodiacAstrologyStoreСhats = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacAstrologyStoreСhats/ZodiacAstrologyStoreСhats"
));
const ZodiacСhatsRedirect = lazy(() => import("./sites/ZodiacLandings/ZodiacСhatsRedirect/ZodiacСhatsRedirect"));
const ZodiacAstrologyStoreConcept = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacAstrologyStoreConcept/ZodiacAstrologyStoreConcept"
));
const ZodiacPlaymarket = lazy(() => import("./sites/ZodiacLandings/ZodiacPlaymarket/ZodiacPlaymarket"));
const ZodiacCompatibilityEs = lazy(() => import("./sites/ZodiacLandings/ZodiacCompatibilityEs/ZodiacCompatibilityEs"));
const ZodiacCompatibilityZodi = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityZodi/ZodiacCompatibilityZodi"
));
const ZodiacCompatibilityCbo = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityCbo/ZodiacCompatibilityCbo"
));
const ZodiacCompatibilityPinterest = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityPinterest/ZodiacCompatibilityPinterest"
));
const ZodiacCompatibilitySnapchat = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilitySnapchat/ZodiacCompatibilitySnapchat"
));
const ZodiacCompatibilitySnapchatSecond = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilitySnapchatV2/ZodiacCompatibilitySnapchatSecond"
));
const ZodiacCompatibilityTiktokSecond = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityTiktokV2/ZodiacCompatibilityTiktokSecond"
));
const ZodiacCompatibilityTwitter = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityTwitter/ZodiacCompatibilityTwitter"
));
const ZodiacCompatibilityTwitterSecond = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityTwitterV2/ZodiacCompatibilityTwitterSecond"
));
const ZodiacCompatibilityTwitterThird = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityTwitterV3/ZodiacCompatibilityTwitterThird"
));
const ZodiacCompatibilityTwitterFourth = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityTwitterV4/ZodiacCompatibilityTwitterFourth"
));
const ZodiacCompatibilityV2 = lazy(() => import("./sites/ZodiacLandings/ZodiacCompatibilityV2/ZodiacCompatibilityV2"));
const ZodiacCompatibilityDownload = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityDownload/ZodiacCompatibilityDownload"
));
const ZodiacCompatibilityTiktok = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilityTiktok/ZodiacCompatibilityTiktok"
));
const ZodiacCelebrityFirst = lazy(() => import("./sites/ZodiacLandings/ZodiacCelebrityV1/ZodiacCelebrityFirst"));
const ZodiacCelebritySecond = lazy(() => import("./sites/ZodiacLandings/ZodiacCelebrityV2/ZodiacCelebritySecond"));
const ZodiacZodiCelebrityFirst = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacZodiCelebrityV1/ZodiacZodiCelebrityFirst"
));
const ZodiacCompatibilitySoulmate = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilitySoulmate/ZodiacCompatibilitySoulmate"
));
const QuizSmall = lazy(() => import("./sites/QuizSmall/QuizSmall"));
const ZodiacCompatibilitySoulmateV2 = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilitySoulmateV2/ZodiacCompatibilitySoulmate"
));
const ZodiacCompatibilitySoulmateV3 = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilitySoulmateV3/ZodiacCompatibilitySoulmate"
));
const ZodiacCompatibilitySoulmateV4 = lazy(() => import(
  "./sites/ZodiacLandings/ZodiacCompatibilitySoulmateV4/ZodiacCompatibilitySoulmate"
));
const MentalHealthRoutes = lazy(() => import("./sites/ZodiacLandings/ZodiacCompatibilityMentalHealth/router"));
const ZodiacCompatibilityRatings = lazy(() => import("./sites/ZodiacLandings/ZodiacCompatibilityRatings/Ratings"));
const ZodiacChatsVideoFeed = lazy(() => import("./sites/ZodiacLandings/ZodiacChatsVideoFeed/ZodiacChatsVideoFeed"));
const NybundleBlackFriday = lazy(() => import("./sites/ZodiacLandings/NybundleBlackFriday/NybundleBlackFriday"));
const GuidesBlackFriday = lazy(() => import("./sites/ZodiacLandings/NybundleBlackFriday/GuidesBlackFriday"));
const ChatsSimpleRegistration = lazy(() => import(
  "./sites/ZodiacLandings/ChatsSimpleRegistration/ChatsSimpleRegistration"
));
const ChatsAnswers = lazy(() => import("./sites/ZodiacLandings/ChatsSimpleRegistration/ChatsAnswers"));
const ChatsTopAdvisor = lazy(() => import("./sites/ZodiacLandings/ChatsSimpleRegistration/ChatsTopAdvisor"));
const AppImageContainer = lazy(() => import("./sites/AppImage/AppImageContainer"));
const TestLanding = lazy(() => import("./sites/TestLanding"));
const QuizSmallExperiment = lazy(() => import("./sites/QuizSmallExperiment/QuizSmallExperiment"));
const ZodiacAstrologyStatements = lazy(() => import("./sites/ZodiacAstrologyStatements"));
const ZodiacAstrologyStatementsRightPlace = lazy(() => import("./sites/ZodiacAstrologyStatementsRightPlace"));
const ZodiacAstrologyStatementsReviews = lazy(() => import("./sites/ZodiacAstrologyStatementsReviews"));
const ZodiacAstrologyStatementsReason = lazy(() => import("./sites/ZodiacAstrologyStatementsReason"));

export const SITES_MAP = {
  "/nebulatalk-landing": Nebulatalk,
  "/zodiac-compatibility-landing-answers": ZodiacCompatibilityAnswers,
  "/zodiac-compatibility-landing": ZodiacCompatibility,
  "/zodiac-compatibility-landing-new": ZodiacCompatibilityNew,
  "/zodiac-compatibility-landing-new-2": ZodiacCompatibilityNewV2,
  "/zodiac-compatibility-landing-red": ZodiacCompatibilityRed,
  "/zodiac-compatibility-landing-new-purple": ZodiacCompatibilityPurple,
  "/zodiac-compatibility-landing-xmas": ZodiacCompatibilityXmas,
  "/zodiac-compatibility-landing-playmarket": ZodiacCompatibilityPlaymarket,
  "/zodiac-questions-landing": ZodiacQuestions,
  "/zodiac-appstore-landing": ZodiacAppstore,
  "/chats-review": ZodiacChatsReview,
  "/astrology-store": ZodiacAstrologyStore,
  "/chats-appstore": ZodiacAstrologyStoreСhats,
  "/chats-redirect": ZodiacСhatsRedirect,
  "/astrology-store-сoncept": ZodiacAstrologyStoreConcept,
  "/zodiac-playmarket-landing": ZodiacPlaymarket,
  "/zodiac-compatibility-landing-es": ZodiacCompatibilityEs,
  "/zodiac-compatibility-zodi": ZodiacCompatibilityZodi,
  "/zodiac-compatibility-landing-2": ZodiacCompatibilityV2,
  "/zodiac-compatibility-landing-download": ZodiacCompatibilityDownload,
  "/zodiac-compatibility-landing-cbo": ZodiacCompatibilityCbo,
  "/zodiac-compatibility-celebrity-1": ZodiacCelebrityFirst,
  "/zodiac-compatibility-zodi-celebrity-1": ZodiacZodiCelebrityFirst,
  "/zodiac-compatibility-celebrity-2": ZodiacCelebritySecond,
  "/zodiac-compatibility-landing-snapchat": ZodiacCompatibilitySnapchat,
  "/zodiac-compatibility-landing-snapchat-2": ZodiacCompatibilitySnapchatSecond,
  "/zodiac-compatibility-landing-tiktok-2": ZodiacCompatibilityTiktokSecond,
  "/zodiac-compatibility-landing-pinterest": ZodiacCompatibilityPinterest,
  "/zodiac-compatibility-landing-twitter": ZodiacCompatibilityTwitter,
  "/zodiac-compatibility-landing-twitter-2": ZodiacCompatibilityTwitterSecond,
  "/zodiac-compatibility-landing-twitter-3": ZodiacCompatibilityTwitterThird,
  "/zodiac-compatibility-landing-twitter-4": ZodiacCompatibilityTwitterFourth,
  "/zodiac-compatibility-landing-tiktok": ZodiacCompatibilityTiktok,
  "/zodiac-compatibility-rose": ZodiacCompatibilityRose,
  "/astrology-compatibility-concept": AstrologyCompatibilityConcept,
  "/zodiac-valentines-day": ZodiacCompatibilityValentine,
  "/zodiac-compatibility-red-2": ZodiacCompatibilityRedV2,
  "/zodiac-compatibility-soulmate": ZodiacCompatibilitySoulmate,
  "/zodiac-compatibility-soulmate-2": ZodiacCompatibilitySoulmateV2,
  "/zodiac-compatibility-soulmate-3": ZodiacCompatibilitySoulmateV3,
  "/zodiac-compatibility-soulmate-4": ZodiacCompatibilitySoulmateV4,
  "/zodiac-mental-health": MentalHealthRoutes,
  "/zodiac-compatibility-ratings": ZodiacCompatibilityRatings,
  "/chats-video-feed": ZodiacChatsVideoFeed,
  "/nybundle-black-friday": NybundleBlackFriday,
  "/guides-black-friday": GuidesBlackFriday,
  "/app-image": AppImageContainer,
  "/chats-simple-registration": ChatsSimpleRegistration,
  "/chats-answers": ChatsAnswers,
  "/chats-top-advisor": ChatsTopAdvisor,
  "/test": TestLanding,
  "/quiz-small": QuizSmall,
  "/quiz-test": QuizSmallExperiment,
  "/zodiac-astrology-statements": ZodiacAstrologyStatements,
  "/zodiac-astrology-statements-right-place": ZodiacAstrologyStatementsRightPlace,
  "/zodiac-astrology-statements-reviews": ZodiacAstrologyStatementsReviews,
  "/zodiac-astrology-statements-reason": ZodiacAstrologyStatementsReason,
};
