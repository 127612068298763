import { FeatureFlagsDecorator } from "./FeatureFlagsDecorator";
import { getLandingId } from "../../utils/landing-id";

export class LandingIdFeatureFlagsDecorator extends FeatureFlagsDecorator {
  getExperimentGroup(experimentName) {
    this.maybeUpdateFeatureFlagsId();
    return super.getExperimentGroup(experimentName);
  }

  getFeatureValue(featureName, defaultValue) {
    this.maybeUpdateFeatureFlagsId();
    return super.getFeatureValue(featureName, defaultValue);
  }

  getFeatureRawResult(featureName) {
    this.maybeUpdateFeatureFlagsId();
    return super.getFeatureRawResult(featureName);
  }

  isFeatureOn(featureName) {
    this.maybeUpdateFeatureFlagsId();
    return super.isFeatureOn(featureName);
  }

  isFeatureOff(featureName) {
    this.maybeUpdateFeatureFlagsId();
    return super.isFeatureOff(featureName);
  }

  maybeUpdateFeatureFlagsId() {
    const newIdentifier = getLandingId();

    if (newIdentifier === this.currentIdentifier) return;

    this.currentIdentifier = newIdentifier;
    super.setIdentifier(newIdentifier);
  }
}
