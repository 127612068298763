import React, { lazy, Suspense } from "react";
import {
  BrowserRouter, Redirect, Route, Switch,
} from "react-router-dom";
import "./App.scss";
import { useApp } from "./hooks/useApp";
import { FeatureFlagsProvider } from "./feature-flags/context";
import ExperimentalLanding from "./components/ExperimentalLanding/ExperimentalLanding";
import { SITES_MAP } from "./sitesMap";

const StartSite = lazy(() => import("./sites/Start/StartContainer"));
const FAQ = lazy(() => import("./sites/FAQ/FAQ"));
const ContactUs = lazy(() => import("./sites/ContactUs/ContactUs"));
const Terms = lazy(() => import("./sites/Terms/Terms"));

function App() {
  const { featureFlags } = useApp();

  return (
    <FeatureFlagsProvider featureFlags={featureFlags}>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            {Object.keys(SITES_MAP).map((path) => {
              const Site = SITES_MAP[path];

              if (!Site) {
                console.error("Component for site not found");
                return null;
              }

              return (
                <Route path={path} key={path}>
                  <ExperimentalLanding fallback={Site} />
                </Route>
              );
            })}
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/" component={StartSite} />
            <Route exact path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </FeatureFlagsProvider>
  );
}

export default App;
