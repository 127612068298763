import { validateRequestData } from "./utils";
import { getPixelParameters } from "../analytics/facebook";
import { joinUrl } from "./url";
import { config } from "../config";

const baseUrl = process.env.REACT_APP_API_URL;
const geocodeUrl = process.env.REACT_APP_GEOCODE_URL;
const geocodeApiKey = process.env.REACT_APP_GEOCODE_API_KEY;

export const draftReq = (state, mediaSources) => {
  const newState = validateRequestData(state);
  const pixelParam = getPixelParameters();
  const userData = {
    order_data: {
      subjects: [
        {
          assigned_to: "partner_1",
          birth_place: {
            place: state.birthPlace.place,
            latitude: 3.135985,
            longitude: 101.6726903,
          },
          gender: newState.gender,
          birth_date: `${newState.date.year}-${newState.date.month}-${newState.date.day}`,
          birth_time: `${newState.time.hour}:${newState.time.minute}`,
        },
        {
          birth_place: {
            place: state.partnerBirthPlace.partnerPlace,
            latitude: 3.135985,
            longitude: 101.6726903,
          },
          birth_date: `${newState.partnerDate.year}-${newState.partnerDate.month}-${newState.partnerDate.day}`,
          gender: newState.partnerGender,
          assigned_to: "partner_2",
          birth_time: `${newState.partnerTime.hour}:${newState.partnerTime.minute}`,
        },
      ],
    },
    media_sources: {
      pixelParam,
      ...mediaSources,
    },
    email: state.email,
    offer_name: newState.offerName,
    project: "Nebula",
  };
  return fetch(`${baseUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  }).then((response) => response.json());
};

export const finalReq = (orderId) => fetch(`${baseUrl}/${orderId}/payments`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
}).then((response) => response.json());

export const updateUserOffer = (orderId, offerName) => fetch(`${baseUrl}/${orderId}`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({ offer_name: offerName }),
}).then((response) => response.json());

export const placesAutocompleteReq = (queryText) => {
  const requestData = {
    api_key: geocodeApiKey,
    text: queryText,
  };
  return fetch(`${geocodeUrl}?${new URLSearchParams(requestData)}`, {
    method: "GET",
  }).then((response) => response.json());
};
export const backendLandingOpen = (body, landingId) => {
  const requestData = {
    landing_id: landingId,
    landing_name: body.landing,
    ad_id: body.af_ad_id ?? null,
    ad: body.af_ad ?? null,
    adset_id: body.af_adset_id ?? null,
    adset: body.af_adset ?? null,
    campaign_id: body.af_c_id ?? null,
    campaign: body.utm_campaign ?? null,
    media_source: body.utm_source ?? null,
  };
  return fetch(
    joinUrl(config.analytics.apiUrl, "/v1/external/events/landing-open"),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    },
  ).catch((e) => console.error(e));
};
export const backendLandingClick = (body, landingId) => {
  const requestData = {
    landing_id: landingId,
    landing_name: body.landing,
    ad_id: body.af_ad_id ?? null,
    ad: body.af_ad ?? null,
    adset_id: body.af_adset_id ?? null,
    adset: body.af_adset ?? null,
    campaign_id: body.af_c_id ?? null,
    campaign: body.utm_campaign ?? null,
    media_source: body.utm_source ?? null,
  };
  return fetch(
    joinUrl(config.analytics.apiUrl, "/v1/external/events/landing-click"),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    },
  ).catch((e) => console.error(e));
};

export const defaultReq = (url, data) => fetch(url, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(data),
});
