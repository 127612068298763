import { useState } from "react";
import { initFeatureFlags } from "../feature-flags/context";

export function useApp() {
  const [featureFlags] = useState(initFeatureFlags());

  return {
    featureFlags,
  };
}
