import qs from "qs";
import { getLandingId } from "./landing-id";

function getQueryParams(locationSearch) {
  return qs.parse(locationSearch, { ignoreQueryPrefix: true });
}

export function getCurrentQueryParams() {
  const currentParams = getQueryParams(window.location.search);

  return {
    ...currentParams,
    // TODO maybe find more efficient place
    landing_id: getLandingId(),
  };
}
