import React, { useContext } from "react";
import { ExperimentContext } from "../Experiment";

const Variant = ({ children, name }) => {
  const experimentGroup = useContext(ExperimentContext);

  if (name !== experimentGroup) return null;

  return <>{children}</>;
};

export default Variant;
