import useQueryParams from "./useQueryParams";
import { processMarketingStrategyOnURL } from "../analytics/processMarketingStrategyOnUrl";

export const useGetResultURL = (action) => {
  let url = new URL(action);
  const currentURL = new URL(window.location.href);
  const skipParams = ["landing_id", "gclid"];
  currentURL.searchParams.forEach((value, key) => {
    if (!skipParams.includes(key)) {
      url.searchParams.append(key, value);
    }
  });
  const { query } = useQueryParams();

  if (query.marketing_conversion_strategy !== null) {
    url = processMarketingStrategyOnURL(url, query.marketing_conversion_strategy, query);
  }

  return url;
};
