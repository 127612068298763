import { getTikTokClickID } from "./tiktok";
import { getLandingName } from "../utils/landing-name";
import { getGoogleAdsClickID } from "./google";
import { getPixelParameters } from "./facebook";
import { ampBackendSentEvent, ampBackendSetUserProps, ampSetUserProps } from "./amplitude";
import { backendLandingOpen } from "../utils/requests";
import { getLandingId } from "../utils/landing-id";
import { config } from "../config";
import { joinUrl } from "../utils/url";
import { omit } from "../utils/omit";

export const trackLandingOpen = async (query) => {
  const landingId = getLandingId();
  const { userAgent, language, vendor } = navigator;
  const controller = new AbortController();
  setTimeout(() => controller.abort(), 10000);

  const bodyData = omit(query, ["landing_id"]);

  if (getTikTokClickID()) { // якщо прийшов з тіктока
    Object.assign(bodyData, {
      hostname: location.hostname,
      pathname: location.pathname,
      pixelId: query.pixel_id ?? "CF98O83C77UEUGLDNOIG",
      landing: getLandingName() || "unknown",
      userAgent,
      language,
      vendor,
    });
  } else if (getGoogleAdsClickID()) { // якщо прийшов з гугла
    Object.assign(bodyData, {
      hostname: location.hostname,
      pathname: location.pathname,
      landing: getLandingName() || "unknown",
      userAgent,
      language,
      vendor,
    });
  } else { // facebook
    const pixelParameters = getPixelParameters();
    Object.assign(bodyData, {
      ...pixelParameters,
      hostname: location.hostname,
      pathname: location.pathname,
      facebookActionSource: "website",
      pixelId: query.pixel_id ?? 1196412314116063,
      landing: getLandingName(),
      userAgent,
      language,
      vendor,
    });
  }

  await fetch(joinUrl(config.analytics.apiUrl, "/v1/external/events/landing-id"), {
    signal: controller.signal,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      landing_id: landingId,
      data: bodyData,
    }),
  }).then((res) => res.json()).catch((e) => e.message ?? "11s");

  ampBackendSentEvent("landing_open", bodyData);
  backendLandingOpen(bodyData, landingId);
};

export const trackExperiment = (experimentName, experimentGroup) => {
  ampBackendSetUserProps({ [experimentName]: experimentGroup });

  const bodyData = {
    test: experimentName,
    group: experimentGroup,
    landing_id: getLandingId(),
  };
  fetch(joinUrl(config.analytics.apiUrl, "/v1/external/events/save-landing-test"), {
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(bodyData),
  }).then((res) => res.json()).catch(console.error);
};
